<template>
  <div class="main-div">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <!--标题部分-->
    <div class="title-div">
      <div class="titleDiv">
        <el-row>
          <el-col :span="24">
            <span class="news_title" @click="actiTitleClick">{{title}}</span>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24">
          <div class="subDiv">
            <div class="subTitle">
              投票时间：
            </div>
            <div class="subContentOrange">
              {{startTime}}~{{endTime}}
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="subDiv">
            <div class="subTitle">
              评选规则：
            </div>
            <div class="subContent" v-if="this.rules == 0">
              每天每个账号可投票{{maxCount}}次，可同时为{{voteLimit}}个地标进行投票
            </div>
            <div class="subContent" v-if="this.rules == 1">
              每天每个账号可投票{{maxCount}}次，且仅能投给同一个地标
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="subDiv">
            <div class="subTitle">
              活动概述：
            </div>
            <div class="subContent">
              {{overview}}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--内容部分-->
    <div class="content-div">
      <!--搜索框-->
      <el-row>
        <div class="searchDiv">
          <el-input
            v-model="giName"
            placeholder="请输入地标名称搜索"
            prefix-icon="el-icon-search"
            @keyup.enter.native="seachEnter"
            @clear="voteClick"></el-input>
          <el-button class="moreBtn" @click="seachEnter">搜索</el-button>
        </div>
      </el-row>
      <!--投票区域-->
      <div class="voteDiv">
        <div v-for="(item, index) in voteList" :key="index" class="voteList">
          <div class="vote-img" @click="toDetail(item)">
            <img :src="item.giicture" alt="">
          </div>
          <div class="title" @click="toDetail(item)">{{item.giName}}</div>
          <div class="vote-title">
            <div class="likeImg"><img src="~@/assets/img/like.png" alt=""></div>
            <div class="number">{{item.actiVoteCount}}</div>
            <div class="like">
              <el-button class="to-vote" @click="likeClick(item)">投票</el-button>
              <!--<el-button class="already-vote" v-if="item.isVote == 1">已投</el-button>-->
            </div>
          </div>
        </div>
      </div>
      <!--分页-->
      <div class="page">
        <el-pagination
          :page-sizes="[16, 20, 50, 100]"
          :page-size="pageSize"
          :current-page.sync="page"
          @current-change="pageChange"
          @size-change="handleSizeChange"
          @prev-click="prevClick"
          @next-click="nextClick"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal">
        </el-pagination>
      </div>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 40px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from "axios";
import eventBus from "@/utils/eventBus.js";
export default {
  name: "onlineSelectionDetail",
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标品牌',
        },
        {
          name: '活动展示',
          path: '/giBrand/eventDisplay/index'
        },
        {
          name: '评选详情',
          path: '/giBrand/onlineSelection/onlineSelectionDetail'
        }
      ],
      title: '',
      startTime: '', //投票开始日期
      endTime: '', //投票结束日期
      maxCount: '', //最大投票数量
      voteLimit: '', //投票限制
      overview: '', //活动概述
      rules: 0,
      giName: '', //搜索框
      voteList: [], //投票列表
      page: 1,
      tableTotal: 0,
      pageSize: 16,
      isLike: true,
      businessId: '',
      selectionMulSel: '',//是否多选
      actiIdData: '',
    }
  },
  mounted() {
    this.businessId = this.$route.query.actiId;
    this.title = this.$route.query.actiName;
    //将路由带过来的id存储在session中，用于面包屑跳转
    sessionStorage.setItem('businessId', JSON.stringify(this.businessId ));
    sessionStorage.setItem('actiName', JSON.stringify(this.title ));
    this.voteClick()
  },
  methods: {
    //点击活动名称跳转活动详情
    actiTitleClick() {
      let pathObj = this.pathObj;
      pathObj.push({
        name: '活动详情',
        path: '/giBrand/eventDisplay/eventDetail'
      })
      let actiId = this.businessId
      this.$router.push({
        path: '/giBrand/eventDisplay/eventDetail',
        query: {
          pathObj: pathObj,
          actiId: actiId,
        }
      });
    },

    likeClick(item) {
      //判断是否登录
      if (sessionStorage.getItem("HussarToken")) {
        //调用投票接口
        let params = {}
        params.entryId = item.entryId //投票对象id
        params.selectionMulSel = this.selectionMulSel //是否多选
        params.selectionMaxCount = this.maxCount //最大投票数量
        params.actiId = this.actiIdData
        if (this.voteLimit == "" || this.voteLimit == undefined) {
          params.voteLimit = "0"
        } else {
          params.voteLimit = this.voteLimit //投票限制
        }
        let tokenDataTemp = sessionStorage.getItem("HussarToken")
        let tokenData = JSON.parse(tokenDataTemp)
          axios({
          method: "post",
          url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/onlineselection/voteOperate',
          headers: {
            "Authorization": tokenData //sessionStorage.getItem("HussarToken")
          },
          data: params
        }).then(res => {
          if (res.data.code == 200 || res.data.code == 10000) {
            this.$message.success("投票成功")
            //刷新页面
            this.voteClick()
          } else {
            this.$message.warning(res.data.msg)
          }
        }).catch(err => {
          this.$message.error('请求失败')
        })
      } else {
        //弹出登录弹窗
        eventBus.$emit('toLogin');
      }
    },
    voteClick() {
      let tokenDataTemp = sessionStorage.getItem("HussarToken")
      let tokenData = JSON.parse(tokenDataTemp)
      let params = {}
      params.current = this.page
      params.size = this.pageSize
      params.businessId = this.businessId
      params.registrationName = this.giName
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/onlineselection/achieveActiEntry',
        headers: {
          "Authorization": tokenData || "" //sessionStorage.getItem("HussarToken")
        },
        data: params
      }).then(res => {
        if (res.data.code == 200 || res.data.code == 10000) {
          this.voteList = res.data.data.actiVote
          /*for (let i = 0; i < this.voteList.length; i++) {
            this.voteList[i].url = process.env.VUE_APP_HUSSAR_DEFAULT_API + "/attachment/showPicture?image=" + `${this.voteList[i].giicture}`
          }*/
          this.startTime = res.data.data.selectionStartDate
          this.endTime = res.data.data.selectionEndDate
          this.maxCount = res.data.data.selectionMaxCount
          this.voteLimit = res.data.data.voteLimit
          this.overview = res.data.data.actiOverview
          this.actiIdData = res.data.data.actiId
          this.selectionMulSel = res.data.data.selectionMulSel //是否多选
          if (this.selectionMulSel == 1) {
            this.rules = 0
          } else {
            this.rules = 1
          }
          this.tableTotal = res.data.count
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },
    //搜索
    seachEnter() {
      this.voteClick()
    },
    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.voteClick()
    },
    pageChange(val) {
      this.page = val
      this.voteClick()
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.voteClick()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.voteClick()
    },
    //跳转产品详情
    toDetail(item) {
      let giName = item.giName
      let giDescript = item.giDescript
      let giicture = item.giicture
      let entryId = item.entryId
      let actiVoteCount = item.actiVoteCount
      let selectionMulSel = this.selectionMulSel
      let selectionMaxCount = this.maxCount
      let voteLimit = this.voteLimit
      let actiId = this.actiIdData
      let businessId = this.businessId
      this.$router.push({
        path: `/giBrand/onlineSelection/onlineSelectionDetail/productDetail`,
        query: {
          giName: giName,
          giDescript: giDescript,
          giicture: giicture,
          entryId: entryId,
          selectionMulSel: selectionMulSel,
          selectionMaxCount: selectionMaxCount,
          voteLimit: voteLimit,
          actiId: actiId,
          businessId: businessId,
          actiVoteCount: actiVoteCount,
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
  .main-div {
    background-color: #f8f9fa !important;
  }
  .breadTopDiv {
    text-align: center;
    .breadTop {
      width: 1200px;
      margin: auto;
    }
  }
  .title-div {
    margin: auto;
    width: 1200px;
    .titleDiv {
      .news_title {
        font-size: 28px;
        font-family: SourceHanSansCN-Bold, SourceHanSansCN;
        font-weight: bold;
        color: #333333;
        line-height: 42px;
      }
      .news_title:hover {
        cursor: pointer;
      }
    }
    .subDiv {
      display: flex;
      .subTitle {
        flex-shrink: 0;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #888888;
        line-height: 21px;
      }
      .subContentOrange {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #EE7C00;
        line-height: 21px;
      }
      .subContent {
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }
    }
  }
  .content-div {
    margin: auto;
    padding: 32px 22px 12px 22px;
    width: 1156px;
    background: #FFFFFF;
    .searchDiv {
      display: flex;
      margin-bottom: 10px;
      margin-left: 15%;
      .moreBtn {
        color: #ffffff;
        position: relative;
        left: -17%;
        width: 100px;
        height: 52px;
        background: #186CF5;
        border-radius: 48px;
      }
    }
  }
  .voteDiv {
    min-height: 112px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .voteList {
      width: 268px;
      height: 266px;
      margin: 20px 10px 20px 0px;
      background: #FFFFFF;
      border-radius: 5px;
      border: 1px solid #EEF2F9;
      .vote-img {
        img {
          width: 268px;
          height: 176px;
        }
      }
      .title {
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
        margin-left: 13px;
        margin-top: 18px;
      }
      .vote-title {
        display: flex;
        justify-content: flex-start;
        .likeImg {
          margin: auto 0px auto 15px;
        }
        .number {
          font-size: 16px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #186CF5;
          line-height: 24px;
          margin: auto 10px auto 10px;
        }
        .like {
          .to-vote {
            width: 72px;
            height: 32px;
            background: #EEF4FF;
            border-radius: 5px;
            border: 1px solid #186CF5;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #186CF5;
            line-height: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 120px;
          }
          .already-vote {
            width: 72px;
            height: 32px;
            background: #F6F8FB;
            border-radius: 5px;
            border: 1px solid #DEE2EA;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #C3C9D2;
            line-height: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 120px;
          }
        }
      }
    }
    .voteList:hover {
      cursor: pointer;
    }
  }
  .page {
    margin: 20px 0px 10px;
    text-align: center;
  }
  ::v-deep .el-row {
    margin: 20px 0;
  }
  ::v-deep .el-input--prefix .el-input__inner {
    width: 784px;
    height: 52px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px 0px rgba(20,110,233,0.12);
    border-radius: 48px;
    border: 1px solid #E3EBF9;
  }
</style>
